import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { PaymentCondition } from '../interface/paymentCondition';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { DataService } from './data.service';
import { ApiFinanceOUT } from '../interface/ro-finance';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private _isFromMatrix = true;


  readonly SPECIAL_DESCRIPTIONS = ["999", "Pagamento à vista"];

  constructor(
    private http: HttpClient,
    private _dataService: DataService
  ) { }

  async getAll(cnpj?: string): Promise<PaymentCondition[]> {
    const url = `${environment.mkgoURL}/api/v1/paymentConditions`;
    const header = await firstValueFrom(this._dataService.httpOptions(cnpj || this._isFromMatrix));
    const resp: PaymentCondition[] = await this.http.get(url, header).pipe(first()).toPromise() as any;
    resp.map(payment => payment.form = (payment.form as any).padStart(2, '0'))
    return resp.reverse();
  }

  async getById(id: string): Promise<PaymentCondition> {
    const url = `${environment.mkgoURL}/api/v1/paymentConditions/${id}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.get(url, header).pipe(first()).toPromise();
    return resp;
  }

  async create(paymentCondition: PaymentCondition): Promise<string> {
    const url = `${environment.mkgoURL}/api/v1/paymentConditions`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.post(url, paymentCondition, header).pipe(first()).toPromise();
    return resp['id'];
  }

  async update(paymentCondition: PaymentCondition): Promise<string> {
    const url = `${environment.mkgoURL}/api/v1/paymentConditions/${paymentCondition._id}`;
    const payment = paymentCondition;
    delete payment._id;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp = await this.http.put(url, JSON.stringify(payment), header).pipe(first()).toPromise();
    return resp['id'];
  }

  async addToRO(paymentConditions: ApiFinanceOUT[], roID: string) {
    const url = `${environment.mkgoURL}/api/v1/paymentConditions/os/${roID}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const body = { paymentConditions };
    const resp = await this.http.post(url, body, header).pipe(first()).toPromise();
    return resp;
  }

  async removeFromOS(osID: string, paymentsId: string[]) {
    const url = `${environment.mkgoURL}/api/v1/paymentConditions/os/${osID}`;
    const options = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    options["body"] = { "paymentConditions": paymentsId };
    const resp = await this.http.request("DELETE", url, options).pipe(first()).toPromise();
    return resp;
  }

  async updateIntoOS(osID: string, payment: ApiFinanceOUT) {
    const url = `${environment.mkgoURL}/api/v1/paymentConditions/os/${osID}`;
    const options = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp = await this.http.put(url, payment, options).pipe(first()).toPromise();
    return resp;
  }

}
