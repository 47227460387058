import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reduceSum'
})
export class ReduceSumPipe implements PipeTransform {

  /**
   * 
   * @param array Array of items to sum
   * @param accessor The property name or a function to get the value to sum
   * @param initialValue The initial value of the reducer
   * @returns A number representing the sum
   * 
   * @example
   * ```typescript
   * // Example 1: Numeric array
   * const numericArray = [1, 2, 3, 4, 5];
   * new ReduceSumPipe().transform(numericArray); // returns 15
   * 
   * // Example 2: Array of objects with a numeric property
   * const arrayOfObjects = [{ value: 10 }, { value: 20 }, { value: 30 }];
   * new ReduceSumPipe().transform(arrayOfObjects, "value"); // returns 60
   * 
   * // Example 3: Array of complex objects with a helper function to retrieve the value for the reducer
   * const complexArray = [{ debit: 10, credit: 20 }, { debit: 15, credit: 25 }];
   * const helperFunction = (item) => item.credit - item.debit;
   * new ReduceSumPipe().transform(complexArray, helperFunction); // returns 20
   * ```
   */
  transform<T>(array: Array<T>, accessor?: keyof T | ((item: T) => number), initialValue = 0): number {
    return array.reduce((acc, item) => {
      if (accessor) {
        if (typeof accessor === 'function') {
          return acc + (accessor(item) || 0);
        }
        return acc + ((item[accessor] as unknown as number) || 0);
      }
      return acc + (item as unknown as number);
    }, initialValue);
  }

}
