import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ObjectId } from '../../shared/type-aliases/object-id';
import { DataService } from '../data.service';
import { MkgSession } from './mkg-session';
import { CashTitle } from 'src/app/interface/cash-title';
import { CashTitleHandlerService } from '../cash-title-handler.service';

@Injectable({
  providedIn: 'root'
})
export class SessionTitleService extends MkgSession<CashTitle> {

  private readonly _isFromMatrix = false;
  private _http = inject(HttpClient);
  private _dataService = inject(DataService);
  private _cashTitleHandlerService = inject(CashTitleHandlerService);


  constructor() {
    super("titleSessionHash");
  }

  override closeSession(titleId: ObjectId, sessionId: ObjectId) {
    const url = `${environment.mkgoURL}/api/v1/titles/${titleId}/session/close/${sessionId}`;
    return this._dataService.httpOptions(this._isFromMatrix)
      .pipe(
        switchMap(options => {
          this.createHash();
          let params = options.params || new HttpParams();
          params = params.set('hash', this.sessionHash);
          options.params = params;
          return this._http.get<object>(url, options)
            .pipe(
              map(apiTitle => this._cashTitleHandlerService.complyAPP(apiTitle))
            )
        })
      )
  }

  override forceCloseAllSessions(osId: ObjectId) {
    const url = `${environment.mkgoURL}/api/v1/os-sessions/${osId}`;
    return this._dataService.httpOptions(this._isFromMatrix)
      .pipe(
        switchMap(options => this._http.post(url, {}, options))
      );
  }

}
