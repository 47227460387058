export enum DESCRIPTIVE_ITEM_STATUS {
    ACTIVE = 1,
    INACTIVE = 2,
    DESCRIPTIVE_APPROVED_AND_ACTIVE = 3
}

export interface RoPartTemp {
    _id?: string;
    code: string;
    description: string;
    amount: number;
    saleValue: number;

    status: DESCRIPTIVE_ITEM_STATUS;
    internalObservation?: string;
    observation: string;

    /** id of product into Fraga catalog used to register this part */
    idFraga?: string;

    /** local only */
    _netValue?: number;
    _discountProp?: number;
    _discountPropGeral?: number;
}
