import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObjectId } from '../shared/type-aliases/object-id';
import { IsoString } from '../shared/type-aliases/iso-string';
import { OperationFunction } from '../shared/lists/operation-functions';
import { MoveTypes } from '../shared/lists/move-type';


export interface ApiPaginatedQueryParams {
  page?: number;
  limit?: number;
  /** 0-false 1-true */
  all?: 0 | 1;
  search?: string;

  /**
   *  For OS, the sort is a object with fields to be sorted with a indicator of sortDirection
   * @examples
   *
   * ~~~typescript
   * // sort by field createdAt in decrescent order
   * { createdAt: -1 }
   *
   * // sort by field budgetCode in crescent order
   * { budgetCode: 1 }
   *
   * // sort by field code, then description
   * { code: 1, description: 1 }
   * ~~~
   *
   * for other queries, the sort is a string and the direction of sort is defined into `order` property
   */
  sort?: object | string;

  /**
   *  Order of `sort` (if it is a string)
   *
   * * 1: crescent
   *
   * * -1: decrescent
   *
   */
  order?: 1 | -1;
}

interface ReceiveDateNull {
  receiveDateStart?: never,
  receiveDateEnd?: never,
}
interface ReceiveDateNotNull {
  receiveDateStart: IsoString,
  receiveDateEnd: IsoString,
}
/** Send both or neither */
type ReceiveDate = ReceiveDateNull | ReceiveDateNotNull;

interface BasePaginatedOSQueryParams extends ApiPaginatedQueryParams {
  /** last updated time of OS */
  timestamp?: IsoString;
  start?: IsoString,
  end?: IsoString,
  additionalState?: ObjectId;
  user?: string;
  client?: string;
  codeSystem?: number;
  budgetCode?: number;
}

export type ApiPaginatedOSQueryParams = BasePaginatedOSQueryParams & ReceiveDate;
interface MovementDateNull {
  movementDateStart?: never,
  movementDateEnd?: never,
}
interface MovementDateNotNull {
  movementDateStart: IsoString,
  movementDateEnd: IsoString,
}
/** Send both or neither */
type MovementDate = MovementDateNull | MovementDateNotNull;

interface CreateDateNull {
  createDateStart?: never,
  createDateEnd?: never,
}
interface CreateDateNotNull {
  /** Date of receive creation, the full title will be returned */
  createDateStart: IsoString,
  /** Date of receive creation, the full title will be returned */
  createDateEnd: IsoString,
}
/** Send both or neither */
type CreateDate = CreateDateNull | CreateDateNotNull;

interface ExpirationDateNull {
  expirationDateStart?: never,
  expirationDateEnd?: never
}
interface ExpirationDateNotNull {
  expirationDateStart: IsoString,
  expirationDateEnd: IsoString,
}

type ExpirationDate = ExpirationDateNull | ExpirationDateNotNull;

/**
 * At passing a date into params, you should to pass the startDate and the respective endDate,
 * otherwise the filter will not work
 */
export interface BaseApiPaginatedTitleQueryParams extends ApiPaginatedQueryParams {
  /** 
   * A general text to find by
   * 
   * @see invoiceNumber if you are looking for the titles of an especific invoice
   * @see codeSystem if you are looking for the titles of an especific OS
   * @override
   */
  search?: string;
  orderId?: string,
  companyOrder?: string,
  clientSupplier?: ObjectId,
  type?: ObjectId[];
  expenseType?: ObjectId[];
  /**
   * `gt` Titles with balance greather than zero
   * `eq` Titles without balance
   */
  balance?: ['gt'] | ['eq'] | ['gt', 'eq'] | [];

  /** codeSystem of OS */
  companyCode?: string

  /** Number of the invoice that creted the title (detached) */
  invoiceNumber?: number;
  paymentCondition?: ObjectId;
  valueStart?: number;
  valueEnd?: number;

  boleto?: boolean
}

export type ApiPaginatedTitleQueryParams = BaseApiPaginatedTitleQueryParams & MovementDate & CreateDate & ExpirationDate;

export interface ApiPaginatedPartsQueryParams extends ApiPaginatedQueryParams {
  /**
   * 1 - bigger than
   * 2 - less than
   * 3 - equal to
   */
  stockCondition?: 1 | 2 | 3;
}

export interface ApiPaginatedMovementQueryParams extends ApiPaginatedQueryParams {
  moveType?: MoveTypes;
  functionType?: OperationFunction;
  /** 1 -> greater than 0
   *  0 -> lower than 0
   */
  balance?: 1 | 0;
  item: string;
  startDate?: IsoString;
  endDate?: IsoString;
  sortField?: string;
  direction?: "asc" | "desc";
  isBalanceAboveZero?: 1 | 0;
}

export interface ApiPaginatedResponse<datatype> {
  docs: Array<datatype>,
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  nextPage: any;
  page: number;
  pagingCounter: number;
  prevPage: any;
  totalDocs: number;
  totalPages: number;
}

export interface ApiPaginatedTitlesResponse<datatype> extends ApiPaginatedResponse<datatype> {
  totals?: {
    balancePayTotal: number;
    balanceReceiveTotal: number;
    valuePayTotal: number;
    valueReceiveTotal: number;
    _id: ObjectId;
  }
}

interface ModuleData<dataType = any> {
  cache: Map<string, dataType>;
  timestamp: number;
}

type ModuleMap = Map<string, ModuleData>;

type CompanyMap = Map<string, ModuleMap>;

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  private _companyMap: CompanyMap = new Map();

  constructor() { }

  getData<dataType>(companyId: string, moduleId: string): ModuleData<dataType> | undefined {
    const moduleMap = this._companyMap.get(companyId);
    if (moduleMap) {
      const moduleData = moduleMap.get(moduleId);
      if (moduleData) {
        return moduleData;
      }
    }
    return {
      cache: new Map<string, dataType>(),
      timestamp: 0
    }
  }

  setData(companyId: string, moduleId: string, data: ModuleData) {
    const moduleMap: ModuleMap = this._companyMap.get(companyId) || new Map();
    const moduleData = moduleMap.get(moduleId) || { cache: new Map(), timestamp: 0 };
    moduleData.cache = this._mergeMaps(moduleData.cache, data.cache);
    moduleData.timestamp = data.timestamp;
    moduleMap.set(moduleId, moduleData);
    this._companyMap.set(companyId, moduleMap);
  }

  /** A helper for config the HttpParams request */
  static getParams(options: ApiPaginatedQueryParams | object): HttpParams {
    let params = new HttpParams();
    if (options) {
      const keys = Object.keys(options);
      for (const key of keys) {
        switch (key) {
          case "all":
          case "limit":
          case "page":
            const value = options[key];
            if (typeof value === 'string') {
              params = params.append(key, value)
            } else if (Number.isFinite(value)) {
              params = params.append(key, `${value}`);
            }
            break;
          case "timestamp":
          case "search":
            if (options[key]) {
              params = params.append(key, `${options[key]}`);
            }
            break;
          case "state":
            params = params.append(key, `[${options[key]}]`);
            break;
          case "sort":
            if (keys.includes('order')) {
              // here, sort is a string
              params = params.append(key, `${options[key]}`);
            } else {
              // here, sort is a object
              params = params.append(key, JSON.stringify(options[key]))
            }
            break;
          default:
            if (options[key] !== undefined && options[key] !== null) {
              params = params.append(key, `${options[key]}`);
            }
            break;
        }
      }
    }
    return params;
  }

  private _mergeMaps(...maps: Map<string, any>[]) {
    const newMap = new Map();
    maps.forEach(m => {
      for (const key of Array.from(m.keys())) {
        newMap.set(key, m.get(key));
      }
    });
    return newMap;
  }

  static notFoundErrorDataToReturn() {
    return {
      docs: [],
      hasNextPage: false,
      hasPrevPage: false,
      limit: 0,
      nextPage: null,
      page: 0,
      pagingCounter: 0,
      prevPage: null,
      totalDocs: 0,
      totalPages: 0
    }
  }
}

