/** Helper to roud a number to 2 decimal points */
export function Money(value: number): number {
    // console.log({value})
    // console.log("!Number.isFinite(value)", !Number.isFinite(value))
    if(!Number.isFinite(value)){
        return value
    }

    const stringNumber = value.toString();
    const threeNumbersAfterDot = stringNumber.match(/\.\d{3}/g)?.[0];

    let lastNumberOfExpressionIsHigherThanOrEqualToFive: boolean;
    let fixedNumber: number = value;
    // console.log({stringNumber, threeNumbersAfterDot})

    if (threeNumbersAfterDot?.length) {
        const lastNumberOfExpression = +threeNumbersAfterDot[3];
        if (!isNaN(lastNumberOfExpression)) {
            lastNumberOfExpressionIsHigherThanOrEqualToFive = lastNumberOfExpression >= 5;
            // console.log({
            //     lastNumberOfExpression, 
            //     threeNumbersAfterDot, 
            //     "threeNumbersAfterDot[3]": threeNumbersAfterDot[3],
            //     lastNumberOfExpressionIsHigherThanOrEqualToFive,
            // })
        }

        // console.log(.005 * (lastNumberOfExpressionIsHigherThanOrEqualToFive ? 1 : -1))
        fixedNumber = value + (.005 * (lastNumberOfExpressionIsHigherThanOrEqualToFive ? 1 : -1)); 
    }

    // console.log({"number(fixedNumber)": Number(fixedNumber), "Number(fixedNumber.toFixed(2))": Number(fixedNumber.toFixed(2))})
    return Number(fixedNumber.toFixed(2));
}