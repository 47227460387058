import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Supplier } from '../interface/supplier';
import { catchError, first, switchMap } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { DataService } from './data.service';
import { SupplierUtilities } from '../class/supplier-utilities';
import { ApiPaginatedQueryParams, ApiPaginatedResponse, PaginationService } from './pagination.service';
import { Observable, firstValueFrom, of } from 'rxjs';
import { API_ERRORS } from '../shared/lists/errors';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  private _isFromMatrix = true;


  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private storage: AngularFireStorage
  ) { }

  async getAll(cnpj?: string): Promise<Supplier[]> {
    const url = `${environment.mkgoURL}/api/v1/suppliers`;
    const header = await firstValueFrom(this.dataService.httpOptions(cnpj || this._isFromMatrix));
    const response: any = await this.http.get(url, header).pipe(first()).toPromise();
    (response["suppliers"] as Supplier[]).forEach(supplier => SupplierUtilities.complyApp(supplier));
    return (response["suppliers"] as Supplier[]).reverse();
  }

  getPaginated(apiParams: ApiPaginatedQueryParams): Observable<ApiPaginatedResponse<Supplier>> {
    const url = `${environment.mkgoURL}/api/v1/suppliers/pages`;
    let params = PaginationService.getParams(apiParams);
    return this.dataService.httpOptions(this._isFromMatrix, params).pipe(
      switchMap(options => this.http.get<ApiPaginatedResponse<Supplier>>(url, options)),
      first(),
      switchMap(resp => {
        if (resp.hasNextPage && apiParams.all === 1) {
          apiParams.limit = resp.totalDocs;
          params = PaginationService.getParams(apiParams);
          return this.dataService.httpOptions(this._isFromMatrix, params).pipe(
            switchMap(options2 => this.http.get<ApiPaginatedResponse<Supplier>>(url, options2)),
            first()
          )
        } else {
          return of(resp)
        }
      }),
      catchError(err => {
        /** If no on client is found, the API will return a error */
        if (err.error.error === API_ERRORS.personNotFound) {
          return of({
            docs: [],
            hasNextPage: false,
            hasPrevPage: false,
            limit: 0,
            nextPage: null,
            page: 0,
            pagingCounter: 0,
            prevPage: null,
            totalDocs: 0,
            totalPages: 0
          })
        } else {
          throw err;
        }
      })
    );
  }

  async get(id: string): Promise<Supplier> {
    const url = `${environment.mkgoURL}/api/v1/suppliers/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.get(url, header).pipe(first()).toPromise();
    return SupplierUtilities.complyApp(resp.supplier);
  }

  async getByDocument(document: string): Promise<Supplier> {
    if (!document) {
      throw new Error("Fornecedor sem documento");
    }
    const url = `${environment.mkgoURL}/api/v1/suppliers/document/${document}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.get(url, header).pipe(first()).toPromise();
    return SupplierUtilities.complyApp(resp.supplier);
  }

  async getCardAdmins(): Promise<Supplier[]> {
    const url = `${environment.mkgoURL}/api/v1/suppliers/filter/data`;
    let header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const params = new HttpParams().set('forceCardAdmin', 'true');
    header['params'] = params;
    const resp: any = await this.http.get(url, header).pipe(first()).toPromise();
    return resp.suppliers.filter((sup: Supplier) => sup.cardAdmin && sup.cardAdmin.length);
  }

  async add(supplier: Supplier): Promise<string> {
    const blob = supplier.photoBlob;
    const apiSupplier = SupplierUtilities.complyApi(supplier);
    const url = `${environment.mkgoURL}/api/v1/suppliers`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const response: Supplier = await this.http.post(url, apiSupplier, header)
      .pipe(first()).toPromise();
    supplier.id = response.id;
    if (blob) {
      supplier.photoBlob = blob;
      await this.updatePhoto(supplier);
    }
    return response.id
  }

  async update(supplier: Supplier, ...args): Promise<Supplier> {
    if (supplier.photoBlob) {
      await this.updatePhoto(supplier);
    }
    const apiSupplier = SupplierUtilities.complyApi(supplier);
    const url = `${environment.mkgoURL}/api/v1/suppliers/${supplier.id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return this.http.put(url, apiSupplier, header).pipe(first()).toPromise();
  }

  private async updatePhoto(supplier: Supplier): Promise<void> {
    const path = `${this.dataService.company.id}/suppliers/${supplier.id}.jpg`;
    await this.storage.upload(path, supplier.photoBlob);
    supplier.photo = await this.storage.ref(path).getDownloadURL().toPromise();
  }

}
