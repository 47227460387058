import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LayoutService } from '../../../service/layout.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MkgUser } from 'src/app/interface/user';

/**
 * Mat dialog params:
 *
 * @param { string } link (optional) link to access when click OK button
 * @param { string } text The text to ask to user, translated with `CONFIRMATION.${text}`
 * @param { any } param param to replace into text
 * @param { any } svg an string svg to use as MatIcon
 * @param { boolean } uniqueAction if true, show just OK button, else show the 'yes' and 'no' buttons
 */
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss'
})
export class ConfirmationDialogComponent implements OnInit, AfterViewInit {

  sub: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      text: string,
      param: any,
      uniqueAction?: boolean,
      link?: string,
      svg?: any
    },
    public layout: LayoutService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    if (data.svg) {
      this.iconRegistry.addSvgIconLiteral('icon', this.sanitizer.bypassSecurityTrustHtml(data.svg));
    }
  }

  ngOnInit() {
    this.layout.loader = false;
    this.sub = this.dialogRef.backdropClick().subscribe(() => this.dialogRef.close());
  }

  ngAfterViewInit(): void {
    const paragraphs = document.querySelectorAll("p.session-data")

    paragraphs.forEach(paragraph => {
      paragraph.setAttribute("style", "font-size: 1rem;");
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onClickYes() {
    if (this.data.link) {
      window.open(this.data.link);
    }
    this.dialogRef.close(true);
  }

}

