import { Observable } from "rxjs";
import { MongoObject } from "src/app/interface/mongo-object";
import { ObjectId } from "src/app/shared/type-aliases/object-id";

export interface SessionError extends MongoObject {
    osId: ObjectId;
    userId: ObjectId;
}

export abstract class MkgSession<T> {

    private _sessionHash!: string;

    constructor(private _localStorageKey: string) {
        this._sessionHash = localStorage.getItem(_localStorageKey) || "";
        localStorage.removeItem(_localStorageKey);
    }


    public saveHashBeforeReload() {
        localStorage.setItem(this._localStorageKey, this.sessionHash)
    }

    get sessionHash() {
        if (!this._sessionHash) {
            this.createHash();
        }
        return this._sessionHash;
    }

    protected createHash() {
        this._sessionHash = `${Date.now()}`;
    }

    public clearHash() {
        this._sessionHash = undefined;
    }
    
    abstract closeSession(itemId: ObjectId, sessionId: ObjectId): Observable<T>;

    abstract forceCloseAllSessions(itemId: ObjectId): Observable<Object>;


}