import { CardAdminInfo } from "./card-admin";
import { IPerson } from "./person";

export enum VALORFRETECAMPO {
  NAO_DEVOLVER = 1,
  PROPRIO = 2,
  DESPESAS = 3
}


export interface Supplier extends IPerson {
  profession?: string;
  cardAdmin?: CardAdminInfo[]
  // cardAdmin?: { tax: number, portion: number }[];
  documentType?: number;

  isTransporter?: boolean;

  /** rg or stateRegistration */
  rg?: string;
  insMun?: string;
  /**
   * @todo
   */
  fancyName?: string;
  creditCrad?: string
  deditCrad?: string

  freightField?: VALORFRETECAMPO;
}
