export enum RO_STATES {
  // orçamento
  budget = 2,
  authorization = 4,
  appointment = 5,

  // OS
  readyToStart = 6, // agendada no APP
  started = 7, // os aberta
  toReceive = 8, // os fechada
  evaluation = 9,
  finished = 10, // os faturada

  rejected = 11, // orçamento rejeitado
  all = 12, // local only
  /**
   * A State related to budgets whose items have been sent to some RO
  */
  closedBudget = 13
}
