import { inject, Injectable } from '@angular/core';
import { CashTitle, Receive } from '../interface/cash-title';
import { PaginatedTitle } from './cash-title.service';
import { ClientService } from './client.service';
import { ExpenseService } from './expense.service';
import { PaymentService } from './payment.service';
import { SupplierService } from './supplier.service';
import { TitleTypeService } from './title-type.service';
import { Money } from '../class/money';
import Decimal from 'decimal.js';

@Injectable({
  providedIn: 'root'
})
export class CashTitleHandlerService {

  private expenseTypes = [];
  private paymentConditions = [];
  private types = [];
  private persons = [];

  private expenseService = inject(ExpenseService);
  private supplierService = inject(SupplierService);
  private clientService = inject(ClientService);
  private paymentService = inject(PaymentService);
  private titleTypeService = inject(TitleTypeService);

  async getData() {
    if (!this.expenseTypes.length) {
      this.expenseTypes = await this.expenseService.getAll()
    }
    if (!this.paymentConditions.length) {
      this.paymentConditions = await this.paymentService.getAll()
    }
    if (!this.types.length) {
      this.types = await this.titleTypeService.getAll()
    }
    if (!this.persons.length) {
      const clients = await this.clientService.getAll();
      const suppliers = await this.supplierService.getAll();
      this.persons = Array.from(clients.values()).concat(suppliers);
    }
  }

  /**
   * Transform a API object title into a MekanciWeb CashTitle
   *
   * Make sure to call `getData()` before `complyAPP()`
   */
  complyAPP(apiObject: any): CashTitle {
    const received: Receive[] = apiObject.received || [];
    const cashtitle: CashTitle = {
      _id: apiObject._id,
      idOS: apiObject.idOS,
      companyCode: apiObject.companyCode,
      typeId: apiObject.type,
      supplier_clientId: apiObject.supplier_client,
      expenseTypeId: apiObject.expenseType,
      paymentConditionId: apiObject.paymentCondition,
      invoiceNumber: apiObject.invoiceNumber,
      serial: apiObject.serial,
      observation: apiObject.observation,
      value: apiObject.value,
      parcel: apiObject.parcel,
      balance: apiObject.balance,
      status: apiObject.status,
      bank: apiObject.bank,
      adiantamento: apiObject.adiantamento === 1,
      received: received,
      fees: received.reduce((sum, low) => sum + (low.fees || 0), 0),
      penaltyFee: received.reduce((sum, low) => sum + (low.penaltyFee || 0), 0),
      rate: received.reduce((sum, low) => sum + (low.rate || 0), 0),
      discount: received.reduce((sum, low) => sum + (low.discount || 0), 0),
      rateTitle: apiObject.rateTitle || 0,
      boleto: apiObject?.boleto,
    }

    if (apiObject.movementDate) {
      // prevent initial Date (1970-01-01)
      cashtitle.movementDate = new Date(apiObject.movementDate);
    }

    if (apiObject.expirationDate) {
      // prevent initial Date (1970-01-01)
      cashtitle.expirationDate = new Date(apiObject.expirationDate)
    }

    if (apiObject.provisional) {
      cashtitle.provisional = apiObject.provisional;
    }

    this.calculateBalance(cashtitle);

    cashtitle.expenseType = this.expenseTypes.find(expense => expense._id === cashtitle.expenseTypeId);
    cashtitle.paymentCondition = this.paymentConditions.find(payment => payment._id === cashtitle.paymentConditionId);
    cashtitle.type = this.types.find(_type => _type._id === cashtitle.typeId);
    cashtitle.supplier_client = this.persons.find(person => person.id === cashtitle.supplier_clientId);
    // this.calculateBalance(cashtitle)
    return cashtitle
  }

  complyAPI(cashTitle: CashTitle | PaginatedTitle) {

    // reset payment method

    if (Object.hasOwn(cashTitle, 'rateValue')) {
      cashTitle['rateValue'] = undefined
    }

    if (Object.hasOwn(cashTitle, 'feesValue')) {
      cashTitle['feesValue'] = undefined
    }


    if (Object.hasOwn(cashTitle, 'penaltyFeeValue')) {
      cashTitle['penaltyFeeValue'] = undefined
    }

    if (Object.hasOwn(cashTitle, 'parcelValue')) {
      cashTitle['parcelValue'] = undefined
    }

    cashTitle.status = cashTitle.status || 0;
    cashTitle.rateTitle = cashTitle.rateTitle || 0;

    let apiObject: any = { ...cashTitle };
    apiObject.adiantamento = Number(cashTitle.adiantamento) || 0;
    // apiObject.type =
    delete apiObject.toPay;
    delete apiObject.parcelValue;

    // removing Mongo properties
    delete apiObject.updatedAt;
    delete apiObject.createdAt;
    delete apiObject.company;
    delete apiObject.seq;
    delete apiObject.__v;
    delete apiObject._id;

    // throw error if have receives with same id
    if (cashTitle.received && Array.isArray(cashTitle.received)) {
      const ids = new Set(cashTitle.received.reduce((arr, receive) => arr.concat(receive._id), []));
      if (ids.size !== cashTitle.received.length) {
        throw new Error("Baixas com identificadores iguais")
      }
    }

    if (cashTitle.movementDate) {
      const movementDate = new Date(cashTitle.movementDate);
      if (movementDate.valueOf()) {
        apiObject.movementDate = movementDate.toISOString()
      }
    }

    if (cashTitle.expirationDate) {
      const expirationDate = new Date(cashTitle.expirationDate);
      if (expirationDate.valueOf()) {
        apiObject.expirationDate = expirationDate.toISOString()
      }
    }

    Object.keys(apiObject).forEach(key => {
      if ([undefined, null, ""].includes(apiObject[key])) {
        delete apiObject[key]
      }
    });

    if (cashTitle.type) {
      if (typeof cashTitle.type === "string") {
        apiObject.type = cashTitle.type;
      } else {
        apiObject.type = cashTitle.type._id;
      }
      delete apiObject.typeId
    } else if (cashTitle['typeId']) {
      apiObject.type = cashTitle['typeId'];
    }

    if (apiObject.expenseTypeId) {
      apiObject.expenseType = apiObject.expenseTypeId;
      delete apiObject.expenseTypeId
    }

    if (apiObject.paymentConditionId) {
      apiObject.paymentCondition = apiObject.paymentConditionId;
      delete apiObject.paymentConditionId
    }

    if (apiObject.supplier_clientId) {
      apiObject.supplier_client = apiObject.supplier_clientId;
      delete apiObject.supplier_clientId
    }

    return apiObject
  }

  private calculateFees(toPay: number, cashTitle: CashTitle | PaginatedTitle) {
    const feesValue = toPay ? Money(toPay * (cashTitle.fees / 100)) || 0 : 0;
    return Math.abs(feesValue);
  }

  private calculateRate(toPay: number, cashTitle: CashTitle | PaginatedTitle) {
    const rateValue = toPay ? Money(toPay * (cashTitle.rate / 100)) || 0 : 0;
    return Math.abs(rateValue);
  }

  private calculatePenaltyFee(toPay: number, cashTitle: CashTitle | PaginatedTitle) {
    const penaltyFeeValue = toPay ? Money(toPay * (cashTitle.penaltyFee / 100)) || 0 : 0;
    return Math.abs(penaltyFeeValue);
  }

  calculateBalance(cashTitle: CashTitle | PaginatedTitle) {
    const rateValue = this.calculateFees(cashTitle.balance, cashTitle);
    const feesValue = this.calculatePenaltyFee(cashTitle.balance, cashTitle);
    const penaltyFeeValue = this.calculateRate(cashTitle.balance, cashTitle);

    const toPay = new Decimal(cashTitle.balance ?? 0)
      .plus(rateValue)
      .plus(feesValue)
      .plus(penaltyFeeValue)
      .minus(cashTitle.discount);

    cashTitle.toPay = Money(toPay.toNumber());
  }

}
