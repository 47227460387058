import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from '../../class/utilities';
import { Finance, PreviewTitle } from '../../interface/ro-finance';
import { CashTitleService } from '../../service/cash-title.service';
import { PAYMENT_METHODS } from '../lists/payment-methods';

@Pipe({
  name: 'previewTitles'
})
export class PreviewTitlesPipe implements PipeTransform {

  constructor(
    private cashtitleService: CashTitleService
  ) { }

  transform(finance: Finance): PreviewTitle[] {
    if (!finance || !finance.paymentCondition || !finance.paymentCondition.parcels) {
      return []
    }
    let titles: PreviewTitle[] = [];
    const totalValue = (finance.value || 0);

    let parcels = finance.paymentCondition.parcels;
    if (finance.paymentCondition.form === PAYMENT_METHODS.other) {

      const parcels999 = JSON.parse(finance.jsonAny ?? "[]");
      if (Array.isArray(parcels999) && parcels999.length) {
        parcels = parcels999;
      }
    }
    if(finance.paymentCondition.form === PAYMENT_METHODS.credit || finance.paymentCondition.form === PAYMENT_METHODS.debit){
      const title: PreviewTitle = {
        parcel: 1,
        percentage: 100,
        value: Number(totalValue.toFixed(2)),
        expiration: new Date(),
        condition: finance.paymentCondition
      }
      titles.push(title)
    } else {
      for (const parcel of parcels) {
        let value = parcel.value;
        if (!value) {
          value = Utilities.calcPercentage(parcel.percentage, totalValue);
        }
        // if (finance.paymentCondition.form === PAYMENT_METHODS.other) {
        //   value = Utilities.calcPercentage(parcel.percentage, totalValue);
        // } else {
        //   value = totalValue / finance.paymentCondition.parcels.length;
        // }
        const expiration = parcel.expirationDate || parcel["expiration"];
        const title: PreviewTitle = {
          parcel: parcel.seq,
          percentage: parcel.percentage,
          value: Number(value.toFixed(2)),
          expiration: expiration ? new Date(expiration) : this.cashtitleService.getExpirationDate(parcel.days),
          condition: finance.paymentCondition
        }
        titles.push(title)
      }
    }

    Utilities.fixCentsDifference(titles, 'value', totalValue);
    return titles;
  }

}
