import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { Utilities } from '../class/utilities';
import { CashTitle, Receive } from '../interface/cash-title';
import { Invoice } from '../interface/invoice';
import { NFe } from '../interface/NF-e';
import { NFCe } from '../interface/NFC-e';
import { NFSe, RPS } from '../interface/NFS-e';
import { Finance } from '../interface/ro-finance';
import { environment } from '../../environments/environment';
import { ApiPaginatedTitleQueryParams, ApiPaginatedTitlesResponse, PaginationService } from './pagination.service';
import { Observable, firstValueFrom, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataService } from './data.service';
import { API_ERRORS } from '../shared/lists/errors';
import { ObjectId } from '../shared/type-aliases/object-id';
import { IsoString } from '../shared/type-aliases/iso-string';
import { Address } from '../interface/address';
import { Company } from '../interface/company';
import { Supplier } from '../interface/supplier';
import moment, { Moment } from 'moment';
import { MkgOS } from '../class/mkg-os';
import { CashTitleHandlerService } from './cash-title-handler.service';
import { SessionError } from './session/mkg-session';
import { ConfirmationDialogComponent } from '../component/dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LayoutService } from './layout.service';
import { SessionTitleService } from './session/session-title.service';
import { PaymentCondition } from '../interface/paymentCondition';
import { Operation } from '../interface/operation';


export interface PaginatedTitle {
  readonly available?: 0 | 1;
  readonly _id?: ObjectId;
  readonly company?: ObjectId;
  readonly createdAt: IsoString;
  readonly updatedAt: IsoString;
  readonly seq: number;
  readonly __v: number;

  bank?: {
    code: string;
    name: string;
    operation: string;
    account: string;
  }
  companyCode?: string;
  idOS?: string;
  companyOrder?: string;
  orderId?: string;
  type?: ObjectId;
  readonly supplier_client: {
    _id: ObjectId,
    name: string;
    document: string;
    vehicles: Array<string>;
    available: 0 | 1;
    gender: number;
    lang: number;
    maritalStatus: number;
    blocked: boolean;
    subscribed: boolean;
    kind: any;
    updateHistory: Array<any>;
    createdAt: IsoString;
    updatedAt: IsoString;
    rg: string;
    phone1: string;
    phone2: string;
    address: Address;
    lastUpdate: IsoString;
    type: any;
    documentType: number;
    hash: string,
    marketing: number,
    __v: number,
    fancyName?: string,
    birthday?: IsoString,
    taxpayer?: boolean,
    retCOFINS?: boolean,
    retCSLL?: boolean,
    retINSS?: boolean,
    retIRPJ?: boolean,
    retPIS?: boolean,
    lead?: any,
    email?: string,
    creditLimit?: number,
    cardAdmin?: Supplier['cardAdmin']
  },

  invoiceNumber?: string;
  paymentCondition: ObjectId;
  expenseType?: ObjectId;
  value: number;
  parcel: number;
  observation: string;
  balance: number;
  provisional?: number;
  paymentConditionPopulate?: PaymentCondition;

  /**
 * When the Cash Titles are created from OS or Order, the status is inactive (0).
 * So, when OS is closed, all titles are updated to active (status = 1).
 * Whem a order has its status changed from "ORÇAMENTO"(1) to "PEDIDO"(2), his titles also
 * must be updated to active status.
 *
 * If the title is created from invoice, the status is active directly (1).
 *
 * Whan the title cannot be deleted (already have received lows), the status is seetted to canceled(2)
 *
 * - 0 - inactive
 * - 1 - active
 * - 2 - canceled
 * */
  status: 0 | 1 | 2;
  received: Receive[];
  /** no get por id isso é boolean, no get paginado é number? */
  adiantamento: number;
  expirationDate?: IsoString;
  movementDate: IsoString;
  rateTitle?: number;

  /** Local only */
  cnpj: string;
  fees?: number;
  penaltyFee?: number;
  rate?: number;
  discount?: number;
  /** local only */
  toPay?: number;
}


@Injectable({
  providedIn: 'root'
})
export class CashTitleService {

  private now = new Date();
  cashTitles: CashTitle[];

  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private _dataService: DataService,
    private _cashTitleHandlerService: CashTitleHandlerService,
    private _sessionTitleService: SessionTitleService,
    private _dialog: MatDialog,
    private _layout: LayoutService
  ) { }

  /**
   *  @deprecated Try to use `getPaginated()`, `findOStitles()` or `findOrderTitles()` instead
   */
  async getAll(cnpj: string, skipInactives = true): Promise<CashTitle[]> {
    const url = `${environment.mkgoURL}/api/v1/title`;
    const header = await firstValueFrom(this.dataService.httpOptions(cnpj));
    await this._cashTitleHandlerService.getData();
    const resp = await firstValueFrom(this.http.get<{ titles: any[] }>(url, header).pipe(
      first(),
      tap((resp) => {
        console.log("Todos os títulos:", resp)
        const wrongTitles = [];
        for (const title of resp.titles) {
          const ids = new Set(title.received.reduce((arr, received) => arr.concat(received._id), []));
          if (ids.size !== title.received.length) {
            wrongTitles.push(title)
          }
        }
        if (wrongTitles.length) {
          console.warn('Alguns títulos possuem baixas com o mesmo id', wrongTitles)
        }
      }),
    ));
    let titles: CashTitle[] = [];
    for (let title of resp.titles) {
      const titleApp = await this._cashTitleHandlerService.complyAPP(title);
      titles.unshift(titleApp)
    }
    this.cashTitles = skipInactives ? titles.filter(t => t.status !== 0) : titles;
    return titles;
  }

  async getTitlesBeforeExpirationDateStart(cnpj: string, expirationDate: Moment): Promise<PaginatedTitle[]> {
    const url = `${environment.mkgoURL}/api/v1/title/expiration-date`;
    const header = await firstValueFrom(this.dataService.httpOptions(cnpj));
    const params = new HttpParams().append('expirationDate', expirationDate.toISOString());
    header['params'] = params;
    return firstValueFrom(this.http.get<PaginatedTitle[]>(url, header))
  }

  /**
   * Attention: this method DON'T return inactive titles
   * 
   * if you want to find inactive titles of an OS, use `findOStitles()` instead
   * 
   * if you want to find inactive titles of an Order, use `findOrderTitles()` instead
   * 
   * @param matrix If the company have matrix, pass it to get name of supplier from updateHistory
   */
  getPaginated(cnpj: string, apiParams: ApiPaginatedTitleQueryParams, matrix?: Company): Observable<ApiPaginatedTitlesResponse<PaginatedTitle>> {
    const url = `${environment.mkgoURL}/api/v1/title/pages`;
    let params = PaginationService.getParams(apiParams);
    return this._dataService.httpOptions(cnpj, params).pipe(
      switchMap(options => this.http.get<ApiPaginatedTitlesResponse<PaginatedTitle>>(url, options)),
      first(),
      switchMap(resp => {
        // the API return 100 docs by defult (even if all=1), so if we want all docs we must call it again with param `limit`
        if (apiParams.all && resp.hasNextPage) {
          apiParams.limit = resp.totalDocs;
          params = PaginationService.getParams(apiParams);
          return this._dataService.httpOptions(cnpj, params).pipe(
            switchMap(options2 => this.http.get<ApiPaginatedTitlesResponse<PaginatedTitle>>(url, options2)),
            first()
          )
        } else {
          return of(resp)
        }
      }),
      map(resp => {
        /** @TODO pass it to backend */
        resp.docs.map(title => {
          if (matrix && title.supplier_client['updateHistory']) {
            const clientHistory = title.supplier_client['updateHistory'].find(hist => hist.company === matrix.id);
            if (clientHistory) {
              title.supplier_client.name = clientHistory.name;
            }
          }
          title.cnpj = cnpj;
          return title
        });
        return resp;
      }),
      catchError(err => {
        /** If no one data is found, the API will return a error */
        if (err.error.error === API_ERRORS.notFound) {
          return of({
            docs: <PaginatedTitle[]>[],
            hasNextPage: false,
            hasPrevPage: false,
            limit: 0,
            nextPage: null,
            page: 0,
            pagingCounter: 0,
            prevPage: null,
            totalDocs: 0,
            totalPages: 0
          })
        } else {
          throw err;
        }
      }),
      map(resp => {
        resp.docs.map(t => {
          t.cnpj = cnpj || this.dataService.company.cnpj;
          const received = t.received;
          t.fees = received.reduce((sum, low) => sum + (low.fees || 0), 0);
          t.penaltyFee = received.reduce((sum, low) => sum + (low.penaltyFee || 0), 0);
          t.rate = received.reduce((sum, low) => sum + (low.rate || 0), 0);
          t.discount = received.reduce((sum, low) => sum + (low.discount || 0), 0);
          t.rateTitle = t.rateTitle ?? 0
        });
        return resp
      })
    );
  }

  async getById(id: string): Promise<CashTitle> {
    const url = `${environment.mkgoURL}/api/v1/title/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(false));
    const resp = await firstValueFrom(this.http.get<any>(url, header)
      .pipe(
        catchError(async error => {

          if (error.status === 400 && (error.error as SessionError).userId) {
            const sessionError = error.error as SessionError;
            this._layout.loader = false;
            const result = await firstValueFrom(this._dialog.open(ConfirmationDialogComponent, {
              data: {
                text: "TITLE_IN_USE",
                param: sessionError,
                uniqueAction: false
              }
            }).afterClosed());
            if (result) {
              const os = await firstValueFrom(this._sessionTitleService.closeSession(id, sessionError._id));
              return os;
            }
          }
          throw error;
        })
      ));
    await this._cashTitleHandlerService.getData();
    const title = this._cashTitleHandlerService.complyAPP(resp);
    return title;
  }

  /**
   * @param personId ID of Client or Supplier
   * @returns an array of advance-titles
   */
  async getAdvancesOfPerson(personId: ObjectId): Promise<CashTitle[]> {
    const url = `${environment.mkgoURL}/api/v1/title/client/${personId}`;
    const options = await firstValueFrom(this.dataService.httpOptions(false));
    return firstValueFrom(this.http.get(url, options).pipe(
      first(),
      map((arr: any[]) => arr.map(obj => this._cashTitleHandlerService.complyAPP(obj)))
    ));
  }

  async create(title: CashTitle, cnpj: string): Promise<string> {
    console.log(title)
    const url = `${environment.mkgoURL}/api/v1/title`;
    const header = await firstValueFrom(this.dataService.httpOptions(cnpj));
    const body = JSON.stringify(this._cashTitleHandlerService.complyAPI(title));
    const resp = await firstValueFrom(this.http.post<{ id: ObjectId }>(url, body, header));
    return resp.id;
  }

  async update(title: CashTitle | PaginatedTitle, cnpj: string): Promise<{ id: string }> {
    const url = `${environment.mkgoURL}/api/v1/title/${title._id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(cnpj));
    const body = this._cashTitleHandlerService.complyAPI(title);
    return firstValueFrom(this.http.put<{ id: string }>(url, body, header));
  }


  async filter(
    args: {
      range?: {
        startDate?: string,
        endDate?: string
      }
      forceReceived?: boolean,
      totalByCondition?: boolean,
      balanceOtherThan?: number
    }
  ) {
    let url = `${environment.mkgoURL}/api/v1/title/filter`;
    let params = new HttpParams();

    if (args.range) {
      if (args.range.startDate) {
        params = params.append('startDate', args.range.startDate)
      }
      if (args.range.endDate) {
        params = params.append('endDate', args.range.endDate)
      }
    }

    if (args.hasOwnProperty('forceReceived')) {
      params = params.append('forceReceived', `${args.forceReceived}`)
    }
    if (args.hasOwnProperty('totalByCondition')) {
      params = params.append('totalByCondition', `${args.totalByCondition}`)
    }
    if (Number.isFinite(args.balanceOtherThan)) {
      params = params.append('balanceOtherThan', `${args.balanceOtherThan}`)
    }

    const header = await firstValueFrom(this.dataService.httpOptions(false));
    header['params'] = params;
    return firstValueFrom(this.http.get<{
      received: Receive,
      supplier_client: ObjectId,
      type: ObjectId
    }[]
    >(url, header).pipe(
      map((titles) => titles.map((t: any) => {
        t.received = [t.received];
        return this._cashTitleHandlerService.complyAPP(t)
      }))
    ));
  }

  public async delete(id: string, cnpj: string) {
    const url = `${environment.mkgoURL}/api/v1/title/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(cnpj));
    return firstValueFrom(this.http.delete(url, header))
  }

  /**
   * @returns The titles of OS (including inactives)
   */
  public findOsTitles(idOS: string, codeSystem: string | number, cnpj: string) {
    const url = `${environment.mkgoURL}/api/v1/title/osv2`;
    const params = PaginationService.getParams({
      idOS,
      companyCode: codeSystem
    });

    return this._dataService.httpOptions(cnpj, params).pipe(
      switchMap(options => this.http.get<{ titles: CashTitle[] }>(url, options)),
      first(),
      map(r => r.titles),
      catchError(err => {
        /** If no one data is found, the API will return a error */
        if (err.error.error === API_ERRORS.notFound) {
          return of([] as Array<CashTitle>)
        } else {
          throw err;
        }
      })
    )
  }

  /**
   * @returns The titles of Order (including inactives)
   */
  public findOrderTitles(orderId: string, companyOrder: string | number, cnpj: string) {
    const url = `${environment.mkgoURL}/api/v1/title/osv3`;
    const params = PaginationService.getParams({
      orderId,
      companyOrder
    });

    return this._dataService.httpOptions(cnpj, params).pipe(
      switchMap(options => this.http.get<{ titles: CashTitle[] }>(url, options)),
      first(),
      map(r => r.titles),
      catchError(err => {
        /** If no one data is found, the API will return a error */
        if (err.error.error === API_ERRORS.notFound) {
          return of([] as Array<CashTitle>)
        } else {
          throw err;
        }
      })
    )
  }

  getTotals() {
    const url = `${environment.mkgoURL}/api/v1/title/totals`;
    return this._dataService.httpOptions(false).pipe(
      switchMap((options) => this.http.get<{
        balancePayTotal: number;
        balanceReceiveTotal: number;
        valuePayTotal: number;
        valueReceiveTotal: number;
        _id: ObjectId;
      }>(url, options)))
  }

  public getExpirationDate(days: number, from: Date = new Date()): Date {
    const milisseconds = days * Utilities.MS_DAY;
    let due = new Date(from.getTime() + milisseconds)

    // avoid set due at saturday or sunday, set to monday
    switch (due.getDay()) {
      case 6: // saturday
        due = new Date(this.now.getTime() + milisseconds + Utilities.MS_DAY * 2)
        break;
      case 0: // sunday
        due = new Date(this.now.getTime() + milisseconds + Utilities.MS_DAY)
      default:
        break;
    }
    return new Date(due.setHours(0, 0, 0, 0));
  }


  /** fix rounding difference */
  public fixDifference(titles: CashTitle[], invoiceValue: number): CashTitle[] {
    if (!titles.length) {
      return []
    }
    let sum = 0;
    for (const title of titles) {
      sum += title.value;
    }
    const difference = Number((invoiceValue - sum).toFixed(2));
    titles[0].value = Number(((titles[0].value || 0) + difference).toFixed(2));
    if (titles[0].balance !== undefined) {
      titles[0].balance = Number(((titles[0].balance || 0) + difference).toFixed(2));
    }
    return titles;
  }

  /** 
   * @deprecated
   */
  assemblyTitlesFromInvoice(invoice: Invoice, finance: Finance, titleType: string, expenseType: string, os: MkgOS): CashTitle[] {
    let titles = [];
    for (const parcel of finance.paymentCondition.parcels) {
      const titleValue = Utilities.calcPercentage(parcel.percentage, finance.value);
      let title: CashTitle = {
        status: 1,
        value: titleValue,
        movementDate: new Date(invoice.dispatchDate),
        expirationDate: this.getExpirationDate(parcel.days),
        typeId: titleType,
        supplier_clientId: invoice.sender,
        paymentConditionId: finance.paymentCondition._id,
        expenseTypeId: expenseType,
        parcel: parcel.seq,
        invoiceNumber: invoice.number,
        serial: invoice.serial,
        balance: titleValue,
        adiantamento: false,
        rateTitle: 0
      }
      if (os && os.codeSystem) {
        title.companyCode = os.codeSystem.toString();
        title.idOS = os.id;
      }
      titles.push(title)
    }

    return this.fixDifference(titles, finance.value);
  }

   /** 
   * @deprecated
   */
  assemblyTitlesFromNF(invoice: NFe | NFCe, finance: Finance, titleType: string, expenseType: string, clientId?: string): CashTitle[] {
    let titles = [];

    for (const parcel of finance.paymentCondition.parcels) {
      const titleValue = Utilities.calcPercentage(parcel.percentage, finance.value);
      let title: CashTitle = {
        status: 1,
        value: titleValue,
        movementDate: new Date(),
        expirationDate: this.getExpirationDate(parcel.days),
        typeId: titleType,
        paymentConditionId: finance.paymentCondition._id,
        expenseTypeId: expenseType || "",
        parcel: parcel.seq,
        invoiceNumber: invoice.ide.nNF.toString(),
        serial: invoice.ide.serie.toString(),
        balance: titleValue,
        adiantamento: false,
        rateTitle: 0
      }
      if (clientId) {
        title['supplier_clientId'] = clientId;
      }
      titles.push(title)
    }

    return this.fixDifference(titles, finance.value);
  }

/**
 * @deprecated
 */
  assemblyTitlesFromNFSE(invoice: NFSe, finance: Finance, titleType: string, expenseType: string, clientId?: string): CashTitle[] {
    let titles = [];

    for (const parcel of finance.paymentCondition.parcels) {
      const titleValue = Utilities.calcPercentage(parcel.percentage, finance.value);
      let title: CashTitle = {
        status: 1,
        value: titleValue,
        movementDate: new Date(),
        expirationDate: this.getExpirationDate(parcel.days),
        typeId: titleType,
        paymentConditionId: finance.paymentCondition._id,
        expenseTypeId: expenseType || "",
        parcel: parcel.seq,
        invoiceNumber: invoice.RPS[0].RPSNumero.toString(),
        serial: invoice.RPS[0].RPSSerie,
        balance: titleValue,
        adiantamento: false,
        rateTitle: 0
      }
      if (clientId) {
        title['supplier_clientId'] = clientId;
      }
      titles.push(title)
    }

    return this.fixDifference(titles, finance.value);
  }

  /**
   * @TODO Store the user selected finances into invoice
   * @param nfe The NFe, attention: NFCe hanven't tag <cobr>
   * @param args 
   * @returns The title to create
   */
  assemblyTitlesFromDuplicates(nfe: NFe, args: {
     operation: Operation,
     supplier_clientId: ObjectId,
     paymentConditionId: ObjectId
    }) {
    const titles: CashTitle[] = [];

    for (const dup of nfe.cobr.dup) {
      titles.push({
        status: 1,
        value: dup.vDup,
        balance: dup.vDup,
        adiantamento: false,
        discount: 0,
        movementDate: new Date(nfe.ide.dhEmi),
        expirationDate: moment(dup.dVenc).endOf("day").toDate(), // avoiding fuso
        invoiceNumber: `${nfe.ide.nNF}`,
        serial: `${nfe.ide.serie}`,
        parcel: Number(dup.nDup),
        received: [],
        qtdaPortion: nfe.cobr.dup.length,
        typeId: args.operation.titleTypeId,
        expenseTypeId: args.operation.expenseTypeId,
        supplier_clientId: args.supplier_clientId,
        paymentConditionId: args.paymentConditionId
      })
    }

    return titles;
  }

/**
 * @TODO Store the user selected finances into invoice
 * @returns The titles to create
 */
  assemblyTitlesFromRPS(rps: RPS, args: {
    operation: Operation,
    supplier_clientId: ObjectId,
    paymentConditionId: ObjectId
  } ): CashTitle[]{
    const titles: CashTitle[] = [];

    for(let parcel of rps.ListaParcelas){

      titles.push({
        status: 1,
        value: Number(parcel.PrcValor),
        balance: Number(parcel.PrcValor),
        adiantamento: false,
        discount: 0,
        movementDate: new Date(rps.dCompetencia),
        expirationDate: moment(parcel.PrcDtaVencimento).endOf("day").toDate(),
        invoiceNumber: `${rps.RPSNumero}`,
        serial: `${rps.RPSSerie}`,
        parcel: Number(parcel.PrcNroFatura),
        received: [],
        qtdaPortion: rps.ListaParcelas.length,
        typeId: args.operation.titleTypeId,
        expenseTypeId: args.operation.expenseTypeId,
        supplier_clientId: args.supplier_clientId,
        paymentConditionId: args.paymentConditionId
      })
    }

    return titles;
  }
}

